// Variables
@use 'variables';

@tailwind base;
@tailwind components;

.floatingbackground {
  @apply text-left w-full  block px-2 hover:text-black z-20 text-gray-600 bg-green-100 hover:bg-green-200
};

.clientfloatingbackground {
  @apply text-left w-full block px-2 hover:text-black z-20 text-gray-600 bg-blue-100 hover:bg-blue-200
};

.aliasfloatingbackground{
  @apply text-left w-full block px-2 hover:text-black z-20 text-gray-600 bg-yellow-100 hover:bg-yellow-200
};

.contactfloatingbackground{
  @apply text-left w-full block px-2 hover:text-black z-20 text-gray-600 bg-red-100 hover:bg-red-200
};


@tailwind utilities;

//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');

[v-cloak] {
  display: none;
}

[x-cloak] {
  display: none !important;
}

// Border styling
.b-red {
  border-color: red;
  border-style: outset;
  border-width: 2px;
  text-align: center;
  background-color: rgb(254, 255, 0);
  color: #0a0302;
}
.b-blue {
  border-style: solid;
  border-color: rgba(48, 36, 252, 0.88);
  border-width: 1px;
  background-color: rgba(255, 226, 219, 0.2);
}

.b-green {
  margin: 2px;
  border-style: solid;
  border-color: green;
  border-width: 1px;
}

.narrow10 {
  width: 10%;
}

.narrow50 {
  width: 100%;
  max-width: 100px;
}

.button-height-36 {
  height: 36px;
}

.postit {
  border-style: solid;
  border-color: rgba(140, 140, 140, 0.88);
  border-width: 1px;
  background-color: rgba(252, 242, 169, 0.88);
  padding: 5px 5px 5px 5px ;
}

.noteit {
  border-style: solid;
  border-color: rgba(140, 140, 140, 0.88);
  border-width: 1px;
  background-color: #f8eeaa;
  color: #234a05;
  font-weight: bold;
  padding: 5px 5px 5px 5px ;
  position: fixed;
  top: 130px;
  left: 0;
  z-index: 100;
}

.contact_alert {
  border-style: solid;
  border-color: rgba(140, 140, 140, 0.14);
  border-width: 2px;
  background-color: rgba(252, 242, 169, 0.88);
  padding: 5px 5px 5px 5px ;
}

.xlarge {
  font-size: x-large;
}

.mbc-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 100px;
  padding: 10px;
  margin: 0 0 0;
  font-size: small;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.blink_me {
  animation: blinker 1s linear infinite;
}

.fill_me {
  background-color: #fff6df;
}

@keyframes blinker {
  50% { opacity: 0; }
}

.right {
  position: absolute;
  right: 40px;
}

.notes {
  bottom: 10%;
  z-index: 1000;
  float: left;
  min-width: 100%;
  padding: 10px;
  margin: 0 0 0;
  font-size: small;
  color: #a94442;
  text-align: left;
  background-color: #fbfde0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.blue {
  background-color: rgba(173, 216, 230, 0.23);
}

    //    Time Picker
    //    For this stylingh to work, delete the corresponding style from:  >>>    height: 10em
    //    ../node_modules/vue2-timepicker/src/vue-timepicker.vue
.vue__time-picker .dropdown {
  height: 20em;
}

.vue__time-picker .dropdown .select-list {
  height: 20em;

}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfccc6;
}

.on-top {
  z-index: 1000;
}

.click-or-new {
  background-color: #e3e9fb;
}
